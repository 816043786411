.mainCalFormContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f0f4fc;
}
.InnerForm {
  border: 3px solid #6791e6;
  width: 100%;
  padding: 5px;
  border-radius: 20px;
}
.formContainer {
  display: flex;
  justify-content: center;
  width: 80%;
  border: 1px solid #8bc34a;
  border-radius: 60px;
  padding: 30px;
}

.label {
  position: absolute;

  font-size: 13px;

  background-color: white !important;
  left: 35px;
  top: -10px;
  padding-left: 5px;
  padding-right: 5px;
}
.OutPutlabel {
  color: #161616;
  font-size: 14px;
  font-weight: bold;
  /* word-spacing: 5px; */
}
.subContainer {
  margin-bottom: 30px;
  position: relative;
}

.inputStyle {
  display: block;
  padding: 0.6rem;
  outline: none;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.18px;
  color: black;
  border: none;
  border: 1px solid #9ba8bb;
  border-radius: 4px;
  outline: none;
  box-sizing: border-box;
  width: 100%;

  transition: all 0.8s ease-in-out;
  margin-bottom: 10px;
  border-radius: 10px;
  cursor: pointer;
}
.spanInput {
  border: 1px solid #000;
  width: 100%;
  padding: 12px;
  border-radius: 10px;
  /* background-color: rgb(234, 241, 252); */
  background-color: #ffffff;
  /* box-shadow: 2px 2px 1px #9ba8bb; */
  display: flex;
}

.inputField {
  border: none;
  width: 86%;
  background: transparent;
  background-color: none;
  cursor: pointer;
}
.inputFieldWithout {
  border: none;
  width: 89%;
  background: transparent;
  background-color: none;
  cursor: pointer;
}
.spanWithoutInput {
  border: 1px solid #000;
  width: 100%;
  padding: 12px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  /* box-shadow: 2px 2px 1px #9ba8bb; */
}
.spanInput :focus,
.inputField :focus {
  border: none;
  outline: none;
  background: transparent;
  background-color: none;
}
.spanWithoutInput :focus,
.inputField :focus {
  border: none;
  outline: none;
  background: transparent;
  background-color: none;
}
.subOutput {
  margin-bottom: 7.5px;
  background-color: white;
  padding: 3px 5px 3px 5px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgb(196, 168, 223);
  border: 1px solid #e3ebfa;
}
.badge_pulsate_outer {
  position: relative;
  margin-right: auto;
  margin-left: 0;
  transition: all 0.3s ease-in-out;
}
.badge_pulsate_outer:hover {
  transform: translateY(-10px);
  transition: all 0.3s ease-in-out;
}

.badge_pulsate {
  width: 25px;
  height: 25px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 100%;
  /* background-color: #ffb400; */
  background-color: rgb(147 51 234);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;

  margin-left: 25px;
  /* margin-top: 30px; */
}
.badge_pulsate:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #000;
  animation: pulse 1s ease infinite;
  border-radius: 50%;
  /* border: solid 2px #ffb400;
   */
  border: 2px solid rgb(126 34 206);
  margin-left: 25px;
  cursor: pointer;
  /* margin-top: 10px; */
}
.rx_tooltip {
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  font-size: 10px;
  left: 2px;
  width: 100px;
  top: 37px;
  position: absolute;
  text-align: center;
  /* bottom: 128%; */
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: all 0.3s ease-in-out;
  z-index: 9999;
  background-color: black;
}
.badge_pulsate:hover .rx_tooltip {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
  transition: all 0.3s ease-in-out;
}
.rx_tooltip:after {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;

  transform: rotate(45deg);
  /* bottom: -5px; */
  top: -4px;
  left: 30px;
  margin: 0 auto;
  transition: all 0.3s ease-in-out;
  background-color: rgb(0, 0, 0);
}
.badge_pulsate_outer {
  position: relative;
  margin-right: auto;
  margin-left: 0;
  transition: all 0.3s ease-in-out;
}
.badge_pulsate_outer:hover {
  transform: translateY(-10px);
  transition: all 0.3s ease-in-out;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  60% {
    transform: scale(1.3);
    opacity: 0.4;
  }
  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}
.dollar {
  margin-bottom: 1px;
}
.outputResult {
  color: black;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submitBtn {
  /* margin-top: 7px; */
  display: flex;
  padding: 9px 0 9px 0px;
  outline: none;
  line-height: 24px;
  color: #fff;
  /* background-color: rgb(138, 138, 196); */
  background-color: rgb(168 85 247);
  border: none;
  text-transform: capitalize;
  border-radius: 10px;
  outline: none;
  box-sizing: border-box;
  width: 15%;
  transition: all 0.8s ease-in-out;
  justify-content: center;
  font-size: 17px;
  margin-top: 7px;
  text-align: center;
  /* box-shadow: 0px 0px 10px rgb(124, 124, 192); */
  cursor: pointer;
}
.submitBtn:hover {
  transform: scale(1.2);
  transition: all 0.5s ease-in-out;
}
.inputStyle::placeholder {
  color: white;
}
.outputHeading {
  border-bottom: 1px solid rgb(147 51 234);
  padding-bottom: 5px;
  margin: 0px 0px 6px 0px;
  color: rgb(147 51 234);
  font-size: 30px;
  text-shadow: 2px 2px 2px rgb(243 232 255);
  /* margin-right: 100px; */
  font-weight: bold;
}
.CloseButton {
  display: flex;
  justify-content: flex-end;
  width: 10%;
  border: none;
  background-color: transparent;
  align-items: center;
  font-size: 20px;
  color: rgb(220 38 38);
  position: absolute;
  right: 1px;
  top: -4px;
  border-radius: 100%;
  cursor: pointer;
}

.grid {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(1, 3fr 1fr);
  box-sizing: border-box;
}

.subGrid {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(3, 1fr);
  box-sizing: border-box;

  padding: 10px;
}
.subw3Container {
  margin-top: 50px;
}
.secondLine {
  margin-top: 0;
}
.outputContainer {
  padding: 0px 5px 0px 5px;
  height: 100vh;

  background-color: rgb(243 232 255);

  width: "100%";
}
@media (max-width: 1024px) {
  .mainCalFormContainer {
    width: 97%;
  }
  .spanInput {
    width: 100%;
  }
  .inputField {
    width: 86.5%;
  }
  .outputContaine {
    width: 100%;
  }
  .label {
    font-size: 12px;
  }
  .outputContainer {
    height: auto;
  }
}
@media (max-width: 991px) {
  .subGrid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .submitBtn {
    width: 130px;
    padding: 4px;

    font-size: 22px;
    margin-top: 0px;
  }
  .formContainer {
    width: 100%;
  }
  .InnerForm {
    width: 100vw;
    margin: 0px 0px 0px 20px;
    border: none;
  }
  .secondLine {
    margin-top: -21px;
  }
  .outputContainer {
    height: auto;
  }
  .subw3Container {
    margin-top: 30px;
  }
}
@media (max-width: 425px) {
  .grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .outputContainer {
    height: auto;
  }
  .subOutput {
    padding: 10px;
  }
  .label {
    font-size: 15px;
  }
  .outputResult {
    font-size: 15px;
  }
  .submitBtn {
    width: 120px;
    padding: 6px;
  }
  .subContainer {
    margin-bottom: 17px;
  }

  .InnerForm {
    border: none;
  }
  .subGrid {
    margin: 20px 0px 0px 0px;
    padding: 10px;
  }
  .subw3Container {
    margin-top: 35px;
  }
}
@media (max-width: 320px) {
  .grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .submitBtn {
    width: 80px;
    font-size: 20px;
    margin-top: 0;
  }
  .outputContainer {
    margin: 0px;
  }
  .subContainer {
    margin: 0px;
  }
  .outputHeading {
    font-size: 25px;
  }
  .mainCalFormContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .label {
    font-size: 12px;
  }
  .subw3Container {
    margin-top: 30px;
  }
}
@media (max-width: 375px) {
  .submitBtn {
    width: 100px;
    font-size: 20px;
  }
  .outputContainer {
    margin: 0px;
    height: auto;
  }
  .subContainer {
    margin: 15px;
  }
  .outputHeading {
    font-size: 25px;
  }
  .label {
    font-size: 12px;
  }
  .subGrid {
    margin-top: 10px;
  }
  .secondLine {
    margin-top: -39px;
  }
  .subw3Container {
    margin-top: 22px;
  }
}
