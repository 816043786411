.badge_pulsate {
  width: 25px;
  height: 25px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 100%;
  background-color: #ffb400;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.badge_pulsate:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: pulse 1s ease infinite;
  border-radius: 50%;
  border: solid 2px #ffb400;
}
.rx_tooltip {
  background-color: var(--primary-theme);
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  font-size: 10px;
  width: 110px;
  position: absolute;
  text-align: center;
  bottom: 128%;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: all 0.3s ease-in-out;
  z-index: 9999;
}
.badge_pulsate:hover .rx_tooltip {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
  transition: all 0.3s ease-in-out;
}
.rx_tooltip:after {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  background-color: var(--primary-theme);
  transform: rotate(45deg);
  left: 0;
  bottom: -5px;
  right: 0;
  margin: 0 auto;
}
.badge_pulsate_outer {
  position: relative;
  margin-left: 0;
  margin-left: auto;
}
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  60% {
    transform: scale(1.3);
    opacity: 0.4;
  }
  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}
@media only screen and (max-width: 394px) {
  .container.listView {
    display: block;
  }
  .imageBox.listView {
    padding-top: calc(591.44 / 1127.34 * 100%);
    margin-bottom: 0.5rem;
  }
}
